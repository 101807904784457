import providersImage from '../../../../assets/images/onDemandProviders.png';
import { Heading, Image, Layout, Text } from '../../../components/core';
import { IconAlert } from '../../../components/icons/IconAlert';
import { IconCloudLightning } from '../../../components/icons/IconCloudLightning';
import { IconFrown } from '../../../components/icons/IconFrown';
import { IconGraduationCap } from '../../../components/icons/IconGraduationCap';
import { IconHeartCrack } from '../../../components/icons/IconHeartCrack';
import { IconLamp } from '../../../components/icons/IconLamp';
import { getStylesheet } from '../../../styles';

export const Offerings = (): JSX.Element => (
  <Layout.Shift {...styles.shiftContainer} {...styles.offerings}>
    <Layout.VStack flex={2} space={4}>
      <Heading.h3>Get emotional support right now</Heading.h3>

      <Layout.Shift {...styles.offeringsLeft}>
        <Layout.VStack space={3}>
          <Offering icon={<IconGraduationCap accessibilityHidden />} text="Academic stress" />
          <Offering icon={<IconCloudLightning accessibilityHidden />} text="Anxiety" />
          <Offering icon={<IconFrown accessibilityHidden />} text="Feeling down" />
        </Layout.VStack>

        <Layout.VStack space={3}>
          <Offering icon={<IconLamp accessibilityHidden />} text="Loneliness" />
          <Offering icon={<IconHeartCrack accessibilityHidden />} text="Relationship issues" />
          <Offering
            icon={<IconAlert color="danger.700" accessibilityHidden />}
            text="Suicidal thoughts"
            textColor="danger.700"
          />
        </Layout.VStack>
      </Layout.Shift>
    </Layout.VStack>

    <Layout.Center flex={1} height="100%">
      <Image
        alt="on demand providers"
        aria-hidden
        source={providersImage}
        height="100%"
        width="100%"
        resizeMode="contain"
        resizeMethod="auto"
      />
    </Layout.Center>
  </Layout.Shift>
);

const Offering = ({
  icon,
  text,
  textColor = 'darkText',
}: {
  icon: JSX.Element;
  text: string;
  textColor?: string;
}): JSX.Element => (
  <Layout.HStack space={3} alignItems="center">
    {icon}
    <Text.paraSmall color={textColor}>{text}</Text.paraSmall>
  </Layout.HStack>
);

const styles = getStylesheet({
  offerings: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 8,
    gap: 3,
    justifyContent: 'space-between',
    overflow: 'hidden',
    padding: 6,
  },

  offeringsLeft: {
    flexWrap: 'wrap',
    gap: 3,
    justifyContent: 'space-between',
    maxWidth: '330px',
  },

  otherContact: {
    backgroundColor: 'white',
    borderRadius: 8,
    flex: 1,
    padding: 6,
    shadow: 'large',
  },

  shiftContainer: {
    gap: 3,
  },

  videoCall: {
    backgroundColor: 'white',
    borderRadius: 8,
    flex: 1,
    padding: 6,
    shadow: 'large',
  },

  warningBox: {
    alignItems: 'center',
    backgroundColor: 'warning.50',
    borderColor: 'warning.300',
    borderRadius: 4,
    borderWidth: 1,
    padding: 5,
  },
});
