import { Component, useCallback, useMemo, useState } from 'react';
import { AccessibilityInfo, findNodeHandle, Platform } from 'react-native';
import { useAccessibilityContext } from '../../contexts/accessibilityContext';
import { visuallyHiddenStyle } from '../../utils/accessibility';
import { Button, Layout } from '../core';

export const SkipToMain = (): JSX.Element => {
  const { mainRef } = useAccessibilityContext();

  const [hasFocus, setHasFocus] = useState(false);

  const onBlur = (): void => {
    setHasFocus(false);
  };
  const onFocus = (): void => {
    setHasFocus(true);
  };

  const onSkipToMainPress = useCallback((): void => {
    if (Platform.OS === 'web') {
      const element = mainRef.current as unknown as HTMLElement | undefined;

      if (element?.focus !== undefined) {
        element.focus?.();
      } else {
        document.getElementById('main')?.focus();
      }

      return;
    }

    if (!mainRef.current) {
      return;
    }

    const reactTag = findNodeHandle(mainRef.current as unknown as Component) ?? undefined;
    if (reactTag === undefined) {
      return;
    }

    // iOS (maybe android?)
    AccessibilityInfo.setAccessibilityFocus(reactTag);
  }, [mainRef.current]);

  const styles = useMemo(() => {
    return hasFocus
      ? {
          alignItems: 'flex-start',
          padding: 1,
          width: '100%',
        }
      : {
          ...visuallyHiddenStyle,
        };
  }, [hasFocus]);

  return (
    <Layout.View {...styles}>
      <Button.tertiarySmall
        isLink
        onBlur={onBlur}
        onFocus={onFocus}
        onPress={onSkipToMainPress}
        testID="skip-to-main"
      >
        Skip to main content
      </Button.tertiarySmall>
    </Layout.View>
  );
};
