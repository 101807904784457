import { format } from 'date-fns';
import { Badge, Button, Heading, Layout, Text } from '../../../components/core';
import { RecommendationStatusForUserResponse } from '../../../graphQL';
import { getRoute, useNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';

type FindCareDashboardCardProps = {
  recommendationStatus: RecommendationStatusForUserResponse | undefined;
};

export const FindCareDashboardCard = ({
  recommendationStatus,
}: FindCareDashboardCardProps): JSX.Element | null => {
  const navigate = useNavigate();

  const hasActiveRecommendation = recommendationStatus?.activeRecommendation ?? false;

  let recommendationExpiration = null;
  let formattedDate = null;

  if (recommendationStatus?.expiration !== null && recommendationStatus?.expiration !== undefined) {
    recommendationExpiration = recommendationStatus.expiration;
    formattedDate = format(new Date(recommendationExpiration ?? ''), 'M/d/yy');
  }

  if (hasActiveRecommendation) {
    return (
      <Layout.Shift {...styles.dashboardBox}>
        <Layout.VStack {...styles.dashboardStack} space={3}>
          <Layout.Flex {...styles.flipOrderForAccessibility}>
            <Heading.h2 level={2}>See your current recommendations</Heading.h2>

            <Heading.h4>Care Recommendations</Heading.h4>

            {formattedDate !== null && (
              <Badge.warningSubtle {...styles.expirationBadge}>{`Expires ${
                formattedDate ?? ''
              }`}</Badge.warningSubtle>
            )}
          </Layout.Flex>

          <Text.paraSmall>
            We know that needs can change with time, so these recommendations will only stay active
            for one week.
          </Text.paraSmall>

          <Button.primaryLarge
            {...styles.dashboardButton}
            aria-label="View mental health care recommendation"
            isLink
            testID="button-dashboard-active-recommendation"
            onPress={() => navigate(getRoute('findCareRecommendation', {}))}
          >
            View Recommendation
          </Button.primaryLarge>
        </Layout.VStack>
      </Layout.Shift>
    );
  } else {
    return (
      <Layout.Shift {...styles.dashboardBox}>
        <Layout.VStack {...styles.dashboardStack} space={2}>
          <Layout.Flex {...styles.flipOrderForAccessibility}>
            <Heading.h2 level={2}>Find mental health care that works</Heading.h2>

            <Heading.h4>Care Recommendations</Heading.h4>
          </Layout.Flex>

          <Text.paraSmall>
            Not sure where to start? Complete a brief questionnaire to receive recommendations that
            best suit your needs.
          </Text.paraSmall>

          <Button.primaryLarge
            {...styles.dashboardButton}
            aria-label="Find mental health care"
            isLink
            testID="button-dashboard-find-care"
            onPress={() => navigate(getRoute('findCareStartCare', {}))}
          >
            Get started
          </Button.primaryLarge>
        </Layout.VStack>
      </Layout.Shift>
    );
  }
};

const styles = getStylesheet({
  dashboardBox: {
    backgroundColor: 'white',
    borderColor: 'secondary.200',
    borderRadius: 8,
    borderWidth: 1,
    gap: 6,
    justifyContent: 'space-between',
    overflow: 'hidden',
    padding: 6,
  },

  dashboardButton: {
    alignSelf: 'flex-start',
  },

  dashboardStack: {
    flex: 1,
  },

  flipOrderForAccessibility: {
    flexDirection: 'column-reverse',
    gap: 3,
  },

  expirationBadge: {
    alignSelf: 'flex-start',
  },
});
