import { defaultTo } from 'lodash';
import { SkillDocument } from '../../../.slicemachine/prismicio';
import { Button, Heading, Image, Layout, Text } from '../../components/core';
import { SkillWrapper } from '../../content/skill/components/SkillWrapper';
import { useSkillContext } from '../../contexts';
import { getStylesheet } from '../../styles';

type SelfCareQuizLandingProps = {
  isVisible: boolean;
  skill: SkillDocument;
};

export const SelfCareQuizLanding = ({
  isVisible,
  skill,
}: SelfCareQuizLandingProps): JSX.Element => {
  const { triggerNextSlide } = useSkillContext();

  const data = skill.data;
  const valueProposition = defaultTo(data.value_proposition, '');
  const description = defaultTo(data.description, '');
  const imageUrl = defaultTo(data.thumbnail_image?.url, '');
  const imageAlt = defaultTo(data.thumbnail_image.alt, '');

  const onStartPressed = (): void => {
    triggerNextSlide();
  };

  return (
    <SkillWrapper isVisible={isVisible} hideContinueButton>
      <Layout.View {...styles.topWrapper}>
        <Layout.VStack space={8}>
          <Layout.VStack space={2}>
            {valueProposition && <Heading.h5 marginBottom={2}>{valueProposition}</Heading.h5>}

            <Heading.h2>Let's set your goals.</Heading.h2>
          </Layout.VStack>

          {description && <Text.para>{description}</Text.para>}

          <Button.skillLarge
            isDisabled={!isVisible}
            testID="button-self-care-quiz-start"
            onPress={onStartPressed}
          >
            Start
          </Button.skillLarge>
        </Layout.VStack>

        {imageUrl !== '' && (
          <Image
            {...styles.image}
            alt={imageAlt}
            aria-hidden
            source={{
              uri: imageUrl,
            }}
            resizeMode="contain"
            resizeMethod="auto"
          />
        )}
      </Layout.View>
    </SkillWrapper>
  );
};

const styles = getStylesheet({
  topWrapper: {
    flex: 1,
    justifyContent: 'center',
    width: '100%',
  },

  logo: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  image: {
    maxHeight: '284px',
    maxWidth: '554px',
    height: '100%',
    width: '100%',
  },
});
