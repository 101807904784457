import { useNavigate } from '../routes';
import { useExternalNavigate } from './useExternalNavigate';
import type { ExternalToOptions } from './useExternalNavigate';
import type { NavigateOptions, To } from 'react-router-dom';

export { ExternalToOptions, NavigateOptions, To };

export const useSmartNavigate = (): ((
  to: To,
  toOptions?: NavigateOptions,
  externalToOptions?: ExternalToOptions,
) => void) => {
  const navigate = useNavigate();
  const externalNavigate = useExternalNavigate();

  return (to: To, toOptions?: NavigateOptions, externalToOptions?: ExternalToOptions): void => {
    if (isExternalLink(to)) {
      externalNavigate(to, externalToOptions);
      return;
    }

    navigate(to, toOptions);
  };
};

export const isExternalLink = (to: To): to is string => {
  if (typeof to !== 'string') {
    return false;
  }

  const isExternal = to.substring(0, 4) === 'http';
  const isMailTo = to.substring(0, 6) === 'mailto';
  const isTelephone = to.substring(0, 3) === 'tel';

  return isExternal || isMailTo || isTelephone;
};
