import { defaultTo } from 'lodash';
import { Divider, useToast } from 'native-base';
import { CompletionSlideSliceDefault } from '../../../../../../.slicemachine/prismicio';
import { Heading, Image, Layout, Text } from '../../../../../components/core';
import { useSkillContext } from '../../../../../contexts';
import {
  useFinishSkillReflectionMutation,
  useMarkSkillCompleteMutation,
} from '../../../../../graphQL';
import { getStylesheet } from '../../../../../styles';
import { useSlideEvents } from '../../hooks/useSlideEvents';
import { SkillWrapper } from '../../SkillWrapper';
import { SkillRating } from './SkillRating';
import type { SliceContext } from '../../skillTypes';

type CompletionSlideProps = {
  context: SliceContext;
  slice: CompletionSlideSliceDefault;
};

export const CompletionSlide = ({ context, slice }: CompletionSlideProps): JSX.Element => {
  const { hasVideoSlide } = useSkillContext();
  const [markSkillCompleted] = useMarkSkillCompleteMutation();

  const toast = useToast();
  const [finishSkill] = useFinishSkillReflectionMutation({
    onError: () => {
      toast.show({
        description: 'Reflections could not be saved.',
        duration: 3000,
      });
    },
    onCompleted: ({ finishSkillReflection }) => {
      if (finishSkillReflection.saved === true) {
        toast.show({
          description: 'Reflections saved',
          duration: 3000,
        });
      }
    },
  });

  useSlideEvents(context.slideIndex, {
    onDisplay: () => {
      void markSkillCompleted({
        variables: {
          skillId: context.skillId,
          moduleId: context.moduleId,
        },
      });

      void finishSkill({
        variables: {
          skillId: context.skillId,
          discardReflection: false,
        },
      });
    },
  });

  const mainItem = slice.primary;
  const imageUrl = defaultTo(mainItem.image?.url, '');
  const title = defaultTo(mainItem.title, '');
  const text = defaultTo(mainItem.text, '');

  return (
    <SkillWrapper isVisible={context.isVisible} paddingBottom={0} hideContinueButton>
      <Layout.VStack {...styles.topStack} space={4}>
        {imageUrl && (
          <Image
            {...styles.image}
            alt={mainItem.image.alt ?? 'Image'}
            aria-hidden
            source={{
              uri: imageUrl,
            }}
            resizeMode="contain"
            resizeMethod="auto"
          />
        )}

        <Layout.VStack space={2} alignItems="center">
          <Heading.h2>{title}</Heading.h2>
          {text && <Text.para>{text}</Text.para>}
        </Layout.VStack>

        <Divider backgroundColor="secondary.alpha.10:alpha.10" />

        <SkillRating context={context} skillId={context.skillId} hasVideoSlide={hasVideoSlide} />
      </Layout.VStack>
    </SkillWrapper>
  );
};

const styles = getStylesheet({
  topStack: {
    alignItems: 'center',
    flex: 1,
  },

  image: {
    alignSelf: 'center',
    size: 142,
  },
});

export default CompletionSlide;
