import { SliceZone } from '@prismicio/react';
import { upperFirst } from 'lodash';
import { Heading, Layout, Skeleton } from '../../../components/core';
import { getStylesheet } from '../../../styles';
import { visuallyHiddenFloatStyle } from '../../../utils/accessibility';
import { useViewByType } from '../hooks/useViewByType';
import { components as sliceComponents } from './slice';
import type { HeadingLevel } from '../../../components/core/Heading';

type ViewProps = {
  headingLevel?: HeadingLevel;
  hideHeader?: boolean;
  isCollection?: boolean;
  omitModuleId?: string;
};

export const View = ({
  hideHeader,
  isCollection = false,
  omitModuleId,
  headingLevel = 2,
}: ViewProps): JSX.Element => {
  const type = isCollection ? 'collections' : 'topics';
  const [{ aggregatedSlice, modulesById, viewData }, { loading, error }] = useViewByType(type);

  if (!viewData || loading || error) {
    return (
      <Layout.View>
        <Layout.VStack space={4}>
          {loading ? (
            <Skeleton backgroundColor="white" height={148} />
          ) : (
            <Heading.h4>Could not load</Heading.h4>
          )}
        </Layout.VStack>
      </Layout.View>
    );
  }

  const title = viewData.title || upperFirst(type);
  const shouldHideHeader = Boolean(hideHeader);

  const headerId = `view-header-${type}`;

  const viewContext = {
    headerId,
    isCollection,
    modulesById,
    omitModuleId,
  };

  return (
    <Layout.Flex {...styles.stack} testID={`view-${type}-items`}>
      {!shouldHideHeader && (
        <Layout.Flex {...styles.text}>
          <Heading.h4 id={headerId} level={headingLevel}>
            {title}
          </Heading.h4>
        </Layout.Flex>
      )}

      {shouldHideHeader && (
        <Heading.h2 {...visuallyHiddenFloatStyle} id={headerId} level={headingLevel}>
          Self Care {title}
        </Heading.h2>
      )}

      {aggregatedSlice && (
        <SliceZone context={viewContext} components={sliceComponents} slices={[aggregatedSlice]} />
      )}
    </Layout.Flex>
  );
};

const styles = getStylesheet({
  stack: {
    gap: 4,
  },

  text: {
    alignItems: 'flex-start',
    alignSelf: 'center',
    flex: 1,
    width: '100%',
  },
});
