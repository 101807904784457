import { compact } from 'lodash';
import React from 'react';
import { Layout } from '../../../../../components/core';
import { getStylesheet } from '../../../../../styles';
import { ModuleDocument } from '../../../../module/ModuleTypes';
import { ViewModulesSlice } from '../../../ViewTypes';
import { CollectionModuleBox } from './CollectionModuleBox';
import { TopicModuleBox } from './TopicModuleBox';

type ViewContext = {
  headerId?: string;
  isCollection: boolean;
  modulesById: Record<string, ModuleDocument>;
  omitModuleId?: string;
};

type ViewModulesProps = {
  context: ViewContext;
  slice: ViewModulesSlice;
};

type ModuleLink = Extract<ViewModulesSlice['items'][number]['module'], { id: string }>;

const ViewModules = ({ context, slice }: ViewModulesProps): JSX.Element => {
  const ModuleBox = context.isCollection ? CollectionModuleBox : TopicModuleBox;
  const wrapperStyles = context.isCollection ? styles.collectionWrapper : {};

  const moduleLinks: ModuleLink[] = compact(
    slice.items.map(({ module }) => {
      return 'id' in module ? module : undefined;
    }),
  );

  const moduleBoxes = moduleLinks.map(moduleLink => {
    if (moduleLink.id === context.omitModuleId) {
      return null;
    }

    const fullModule = context.modulesById[moduleLink.id];
    if (!fullModule) {
      return null;
    }

    return (
      <Layout.Flex {...wrapperStyles} flex={1} key={moduleLink.id} role="listitem">
        <ModuleBox module={fullModule} />
      </Layout.Flex>
    );
  });

  if (context.isCollection) {
    return (
      <Layout.Flex
        {...styles.collections}
        aria-labelledby={context.headerId}
        role="list"
        testID="collections"
      >
        {moduleBoxes}
      </Layout.Flex>
    );
  }

  return (
    <Layout.VStack aria-labelledby={context.headerId} space={2} role="list" testID="topics">
      {moduleBoxes}
    </Layout.VStack>
  );
};

export default ViewModules;

const styles = getStylesheet({
  collections: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 2,
    justifyContent: 'space-between',
    width: '100%',
  },

  collectionWrapper: {
    height: '264px',
    minWidth: '45%',
    overflow: 'hidden',
  },
});
