import { createContext, JSX, MutableRefObject, ReactNode, useContext, useRef } from 'react';
import { Layout } from '../components/core';

type View = typeof Layout.View | typeof Layout.ScrollView;

type AccessibilityContextType = {
  mainRef: MutableRefObject<View | undefined>;
};

const AccessibilityContext = createContext<AccessibilityContextType>({
  mainRef: { current: undefined },
});

export const AccessibilityConsumer = AccessibilityContext.Consumer;

export const AccessibilityProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const mainRef = useRef<View | undefined>();

  const providerValue: AccessibilityContextType = {
    mainRef,
  };

  return (
    <AccessibilityContext.Provider value={providerValue}>{children}</AccessibilityContext.Provider>
  );
};

export const useAccessibilityContext = (): AccessibilityContextType => {
  return useContext(AccessibilityContext);
};
