import { Avatar } from 'native-base';
import React from 'react';
import { Button, Heading, Layout, Text } from '../../../components/core';
import { IconMessageSquare } from '../../../components/icons/IconMessageSquare';
import { IconUser } from '../../../components/icons/IconUser';
import { getRoute, useNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';

type CoachChatWidgetProps = {
  coachName: string;
  coachAvatarUrl: string;
  hasUnreadMessages: boolean;
  active?: boolean;
};

export const CoachChatWidget = ({
  coachAvatarUrl,
  coachName,
  hasUnreadMessages,
  active,
}: CoachChatWidgetProps): JSX.Element => {
  const navigate = useNavigate();

  const onChatClick = (): void => {
    navigate(getRoute('coachingChat', {}));
  };

  const chatButton =
    active === true ? (
      <Layout.HStack>
        <Button.primaryMedium
          testID="button-to-coach-chat"
          leftIcon={<IconMessageSquare accessibilityHidden />}
          onPress={onChatClick}
        >
          Chat
        </Button.primaryMedium>
        {hasUnreadMessages && <Layout.Center {...style.chatNotification} />}
      </Layout.HStack>
    ) : (
      <Layout.HStack>
        <Button.secondaryMedium
          testID="button-to-coach-chat"
          leftIcon={<IconMessageSquare accessibilityHidden />}
          onPress={onChatClick}
        >
          View Chat
        </Button.secondaryMedium>
      </Layout.HStack>
    );

  return (
    <Layout.HStack {...style.coachChatWidget}>
      <Layout.HStack space={3} alignItems="center">
        <Layout.Center>
          {coachAvatarUrl ? (
            <Avatar _image={{ alt: 'Coach Avatar' }} aria-hidden source={{ uri: coachAvatarUrl }} />
          ) : (
            <Layout.View {...style.iconUserWrapper}>
              <IconUser {...style.iconUser} size={10} />
            </Layout.View>
          )}
        </Layout.Center>

        <Layout.VStack space={1}>
          <Heading.h3>{coachName}</Heading.h3>
          <Text.caption>Your Coach</Text.caption>
        </Layout.VStack>
      </Layout.HStack>
      {chatButton}
    </Layout.HStack>
  );
};

const style = getStylesheet({
  coachChatWidget: {
    borderRadius: 8,
    justifyContent: 'space-between',
    width: '100%',
  },

  chatNotification: {
    backgroundColor: 'danger.600',
    borderRadius: 10,
    height: 5,
    position: 'absolute',
    right: -5,
    top: -5,
    width: 5,
  },

  iconUser: {
    color: 'secondary.300',
  },

  iconUserWrapper: {
    backgroundColor: 'secondary.100',
    borderRadius: 50,
    borderWidth: 1,
    borderColor: 'secondary.300',
    overflow: 'hidden',
  },
});
