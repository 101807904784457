import { ApolloError } from '@apollo/client';
import { defaultTo } from 'lodash';
import { useOrganizationLogoQuery } from '../../../../graphQL';

type OrganizationLogo = [
  {
    orgName: string;
    url: string;
  },
  {
    loading: boolean;
    error: ApolloError | undefined;
  },
];

export const useOrganizationLogo = (): OrganizationLogo => {
  const { data, loading, error } = useOrganizationLogoQuery();

  const orgName = data?.onboardedHubUser.organizationLogo?.orgName ?? '';
  const url = defaultTo(data?.onboardedHubUser.organizationLogo?.url, '');

  return [
    { orgName, url },
    { loading, error },
  ];
};
