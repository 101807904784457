import { defaultTo } from 'lodash';
import { SkillDocument } from '../../../.slicemachine/prismicio';
import { Button, Heading, Image, Layout, Text } from '../../components/core';
import { IconMantraLogo } from '../../components/icons/IconMantraLogo';
import { ImageMantraHealth } from '../../components/images/ImageMantraHealth';
import { SkillWrapper } from '../../content/skill/components/SkillWrapper';
import { useSkillContext } from '../../contexts';
import { getStylesheet } from '../../styles';

type TourLandingProps = {
  isVisible: boolean;
  orgName: string;
  skill: SkillDocument;
};

export const TourLanding = ({ isVisible, orgName, skill }: TourLandingProps): JSX.Element => {
  const { triggerNextSlide } = useSkillContext();

  const data = skill.data;
  const description = defaultTo(data.description, '');
  const title = defaultTo(data.title, '');
  const imageUrl = defaultTo(data.thumbnail_image?.url, '');
  const imageAlt = defaultTo(data.thumbnail_image.alt, '');

  let descriptionWithOrgName = description;
  if (orgName === '') {
    descriptionWithOrgName = descriptionWithOrgName.replace(
      /\{\{OrganizationName\}\}([’']s)?/gi,
      `Your`,
    );
  } else {
    descriptionWithOrgName = descriptionWithOrgName.replace(/\{\{OrganizationName\}\}/gi, orgName);
  }

  const onStartPressed = (): void => {
    triggerNextSlide();
  };

  return (
    <SkillWrapper isVisible={isVisible} hideContinueButton>
      <Layout.View {...styles.topWrapper}>
        <Layout.VStack {...styles.middleWrapper} space={6}>
          <Layout.View alignItems="center">
            <IconMantraLogo size={30} />
          </Layout.View>

          {title && <Heading.h1 center>{title}</Heading.h1>}

          <Heading.h5 {...styles.logo}>
            by{'  '}
            <ImageMantraHealth height={18} />
          </Heading.h5>

          {description && <Text.para center>{descriptionWithOrgName}</Text.para>}

          <Button.skillLarge
            {...styles.button}
            isDisabled={!isVisible}
            onPress={onStartPressed}
            testID="button-tour-start"
          >
            Get Started
          </Button.skillLarge>
        </Layout.VStack>

        {imageUrl !== '' && (
          <Image
            {...styles.image}
            alt={imageAlt}
            aria-hidden
            source={{
              uri: imageUrl,
            }}
            resizeMode="contain"
            resizeMethod="auto"
          />
        )}
      </Layout.View>
    </SkillWrapper>
  );
};

const styles = getStylesheet({
  topWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    width: '100%',
  },

  middleWrapper: {
    alignItems: 'center',
  },

  logo: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  button: {
    alignSelf: 'center',
    maxWidth: '100%',

    _web: {
      maxWidth: '151px',
    },
  },

  image: {
    maxHeight: '284px',
    maxWidth: '554px',
    height: '100%',
    width: '100%',
  },
});
